// ------------------------------------------- PROOF OF CONCEPT SCRIPT ---------------------------------------------

// --------------------------------------------------- IMPORTS ------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { CachePersistor } from 'apollo-cache-persist'
import { InMemoryCache } from 'apollo-cache-inmemory'
import gql from "graphql-tag";
import Dexie from 'dexie';

// -------------------------------------------------- CONSTANTS -----------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------

const SCHEMA_VERSION      = '1'
const SCHEMA_VERSION_KEY  = 'apollo-schema-version'
const db                  = new Dexie('GQL-PWA-POC');

// -------------------------------------------------- VARIABLES -----------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------

var client      = null;
var persistor   = null;
var authLink    = null;
var httpLink    = null;
var cache       = null;
var loadCounter = 0;

// -------------------------------------------------- FUNCTIONS -----------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------

function query_Network_Cache_Fallback(inQuery, onSuccessCb, onErrorCb){

  let query = gql(inQuery);

  client.query({query}).then((results1) => {
    onSuccessCb(results1)
  }).catch((err1)=>{
    client.query({query, 'fetchPolicy' : 'cache-first'}).then((results2) => {
      onSuccessCb(results2)
    }).catch((err2)=>{
      onErrorCb(err2);
    });
  });

}

function loadQueries(){

  let query = 'query{queries{id,text}}';

  function loadQueriesArray(array){

    let row   = array.shift();
    let query = row.text;

    query_Network_Cache_Fallback(query, (subresults) => {
      if(array.length>0){
        loadQueriesArray(array);
      }
    }, (err)=>{});
  }

  query_Network_Cache_Fallback(query, (result) => {
    loadQueriesArray(result.data.queries);
  }, (err)=>{});
}

function onLoadedExecute(string) {

  loadCounter++;
  console.log('onLoadedExecute '+loadCounter+' : '+string);
  if(loadCounter == 2){
    loadQueries();
  }
}

const getDataItem = async (key) => {

  const results = await db
  .table('toto')
  .where('key')
  .equals(key).toArray();

  let bobo = Object.assign({}, results);
  return bobo['0'].data;
};

const setDataItem = (key, data) => {

  db.table('toto').add({
    key: key,
    data: data
  })
};

async function start() {

  const currentVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY)

  if (currentVersion === SCHEMA_VERSION) {
    await persistor.restore()
  } else {
    await persistor.purge()
    window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
  }

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    }
  };

  client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: cache,
    defaultOptions
  })

  onLoadedExecute('APOLLO_LOADED');
}

function init(){
  
  db.version(1).stores({
    toto: 'key, data',
  });

  httpLink = createHttpLink({
    uri: 'https://www.emdemos.com/graphql/api/graphql'
    //uri: 'https://www.emdemos.com/api/', //GRAPHQL_API
  })

  authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'Access-Control-Allow-Origin': '*',
      },
      fetchOptions: {
          mode: 'cors'
      }
    }
  })

  cache = new InMemoryCache();

  persistor = new CachePersistor({
    cache,
    storage: {
        getItem: getDataItem, 
        setItem: setDataItem,
        removeItem: key => {console.log('storage removeItem : key = ', key)},
      }
  })

  start().catch(e => console.error(e.stack));
}

// --------------------------------------------------- ONLOAD -------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------

window.addEventListener('load', ()=>{

  const button_test   = document.getElementById("button_test");
  const button_clear  = document.getElementById("button_clear");
  const textarea_01   = document.getElementById("textarea_01");
  const textarea_02   = document.getElementById("textarea_02");

  button_test.addEventListener('click', ()=> {

    let query = textarea_01.value.trim();

    query_Network_Cache_Fallback(query, (results) => {
      textarea_02.value = JSON.stringify(results);
    }, (err)=>{});
  });

  button_clear.addEventListener('click', ()=> {
    textarea_02.value = '';
  })

  onLoadedExecute('PAGE_LOADED');
});

// ---------------------------------------------------- MAIN --------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------

init();

// ---------------------------------------------------- END ---------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------



